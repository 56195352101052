import {  graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/Seo";
import parse from "html-react-parser";

const CmsPage = (props: any) => {
  const PAGE_SLUG = ["homepage", "refund_returns"];
  let { data, pageContext } = props;
  const allData: any = data?.wppageData;
  // const seoInfo: any = data?.wppageData?.seo?.fullHead;

  const [forbidden, setForbidden] = useState<boolean>(false);

  useEffect(() => {
    if (PAGE_SLUG.includes(allData.slug)) {
      setForbidden(true);
      navigate("/");
    }
  }, []);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (PAGE_SLUG.includes(allData.slug)) {
      setForbidden(true);
      navigate("/");
    }
  }, []);

  return (
    // {/* {info && <Seo info={info} />} */}
    <>
      {!forbidden && (
        <Layout>
          <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain">
            <div className="privecy-content-area">
              <h1>{allData?.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: allData?.content }}></div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default CmsPage;

export const Head = (props: any) => {
  let { data, pageContext } = props;
  const seoInfo = pageContext.fullHead;
  const parseHtml: any = parse(seoInfo);

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === "application/ld+json"
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }
  return (
    <>
      {parseHtml}
      <Seo info={seoInfo} />
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    wppageData: wpPage(id: { eq: $id }) {
      id
      title
      slug
      content
    }
  }
`;
